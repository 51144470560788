import { observable, computed, action, decorate, observe } from 'mobx'

class ShoppingCart {

  _items = [
    // { name: 'Name', unit_price: 1.22, count: 10, path: '/books/...' }
  ]

  constructor() {
    if (typeof window !== "undefined")
      this._items = JSON.parse(window.localStorage.getItem('cart')) || []
    else
      this._items = []

    observe(this._items, () => {
      console.log('Change made to _items')
      localStorage.setItem('cart', JSON.stringify(this._items))
    })
  }

  get items() {
    return this._items
  }

  get itemCount() {
    return this._items.reduce((prev, cur) => {
      return prev + cur.count
    }, 0)
  }

  get total() {
    return this._items.reduce((prev, cur) => {
      return prev + cur.count * cur.unit_price
    }, 0)
  }

  addItem(_item) {
    let item = Object.assign({}, _item)
    item.count = +item.count
    let existingIndex = this._items.findIndex(el => el.sku === item.sku)
    if (existingIndex !== -1) {
      item.count += this._items[existingIndex].count
      this._items.splice(existingIndex, 1, item)  // do the splicing so we could trigger change event
    }
    else
      this._items.push(item)
  }

  setCountForSku(sku, count) {
    count = +count
    let existingIndex = this._items.findIndex(el => el.sku === sku)
    if (existingIndex !== -1) {
      let item = Object.assign({},  this._items[existingIndex])
      if (count) {
        item.count = count
        this._items.splice(existingIndex, 1, item)  // do the splicing so we could trigger change event
      }
      else
        this._items.splice(existingIndex, 1)
    }
  }

  doEmpty() {
    // splice so that change event is triggered
    this._items.splice(0, this._items.length)
  }

  formatCurrency(amount) {
    return '$ ' + (+amount).toFixed(2)
  }

  calculateShipping(isDomestic) {
    let perItem = isDomestic ? 0.95 : 7.95
    return perItem * this.itemCount
  }
}

decorate(ShoppingCart, {
  _items: observable,
  items: computed,
  itemCount: computed,
  addItem: action,
  doEmpty: action,
  setCountForSku: action
})


export default new ShoppingCart()

/**
 * Shipping:
 US: $0.95  per item
 International: $7.95 per each for items
 */