// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-connect-js": () => import("./../../../src/pages/connect.js" /* webpackChunkName: "component---src-pages-connect-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-materials-guitar-js": () => import("./../../../src/pages/materials/guitar.js" /* webpackChunkName: "component---src-pages-materials-guitar-js" */),
  "component---src-pages-materials-piano-js": () => import("./../../../src/pages/materials/piano.js" /* webpackChunkName: "component---src-pages-materials-piano-js" */),
  "component---src-pages-materials-violin-js": () => import("./../../../src/pages/materials/violin.js" /* webpackChunkName: "component---src-pages-materials-violin-js" */),
  "component---src-pages-schools-js": () => import("./../../../src/pages/schools.js" /* webpackChunkName: "component---src-pages-schools-js" */),
  "component---src-pages-store-js": () => import("./../../../src/pages/store.js" /* webpackChunkName: "component---src-pages-store-js" */),
  "component---src-pages-teaching-tips-hints-js": () => import("./../../../src/pages/teaching-tips/hints.js" /* webpackChunkName: "component---src-pages-teaching-tips-hints-js" */),
  "component---src-pages-teaching-tips-index-js": () => import("./../../../src/pages/teaching-tips/index.js" /* webpackChunkName: "component---src-pages-teaching-tips-index-js" */),
  "component---src-pages-teaching-tips-know-your-student-js": () => import("./../../../src/pages/teaching-tips/know-your-student.js" /* webpackChunkName: "component---src-pages-teaching-tips-know-your-student-js" */),
  "component---src-pages-teaching-tips-special-needs-js": () => import("./../../../src/pages/teaching-tips/special-needs.js" /* webpackChunkName: "component---src-pages-teaching-tips-special-needs-js" */),
  "component---src-pages-technique-11-and-older-js": () => import("./../../../src/pages/technique/11-and-older.js" /* webpackChunkName: "component---src-pages-technique-11-and-older-js" */),
  "component---src-pages-technique-3-year-old-js": () => import("./../../../src/pages/technique/3-year-old.js" /* webpackChunkName: "component---src-pages-technique-3-year-old-js" */),
  "component---src-pages-technique-4-year-old-js": () => import("./../../../src/pages/technique/4-year-old.js" /* webpackChunkName: "component---src-pages-technique-4-year-old-js" */),
  "component---src-pages-technique-5-year-old-js": () => import("./../../../src/pages/technique/5-year-old.js" /* webpackChunkName: "component---src-pages-technique-5-year-old-js" */),
  "component---src-pages-technique-6-7-year-old-js": () => import("./../../../src/pages/technique/6-7-year-old.js" /* webpackChunkName: "component---src-pages-technique-6-7-year-old-js" */),
  "component---src-pages-technique-8-10-year-old-js": () => import("./../../../src/pages/technique/8-10-year-old.js" /* webpackChunkName: "component---src-pages-technique-8-10-year-old-js" */),
  "component---src-pages-technique-toddlers-js": () => import("./../../../src/pages/technique/toddlers.js" /* webpackChunkName: "component---src-pages-technique-toddlers-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

